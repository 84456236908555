@import "nlib/config";

.tableContainer {
  position: relative;
  .transactionsTable {
    .checkboxCell {
      flex: none;
      min-width: fit-content;
      padding-right: 0;
      padding-left: $contentDefaultIndent * 1.5;
      &:not([data-table-head]) {
        align-items: flex-start;
        padding-top: $contentDefaultIndent * 1.375;
      }
    }
    .transactionCell {
      flex: 6;
      justify-content: flex-end;
      width: 6px;
      min-width: 8.25rem;
    }
    .amountCell {
      flex: 6;
      justify-content: flex-end;
      width: 6px;
      min-width: 8rem;
    }
    .placeholderCell {
      flex: 0.5;
      width: 1px;
      padding: 0;
    }
    .extraCell {
      flex: 52;
      width: 52px;
    }
    .progressCell {
      flex: 10;
      width: 10px;
      min-width: 10rem;
    }
    .transactionCell,
    .amountCell,
    .extraCell,
    .progressCell {
      padding-right: $contentDefaultIndent;
      padding-left: $contentDefaultIndent;
    }
    .tableRowHeader {
      .transactionCell {
        > div {
          margin-left: $contentDefaultIndent * 0.75;
        }
      }
    }
  }
  .footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .pagination,
    .pageSize {
      margin-top: $contentDefaultIndent * 2;
    }
    .pagination {
      margin-right: $contentDefaultIndent * 2;
    }
  }
}
