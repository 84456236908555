@import "nlib/config";

.extraCell {
  width: 100%;
  height: 100%;
  padding-right: $contentDefaultIndent / 2;
  .upper {
    display: flex;
    .description {
      display: flex;
      position: relative;
      flex: auto;
      align-items: center;
      height: 21px;
      margin-bottom: $contentDefaultIndent;
      overflow: hidden;
      font-size: 0.75rem;
      .content {
        display: flex;
        position: absolute;
        top: 1px;
        left: 0;
        width: 100%;
        .text {
          padding-left: 12px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          svg {
            font-size: 1rem;
            &.warning {
              color: $uiWarningColor;
            }
            + span {
              margin-left: 7px;
            }
          }
          &.muted {
            color: $uiPlaceholderColor;
          }
        }
      }
    }
    .badge {
      &.active {
        background: $uiLightBlueColor;
        color: $uiWhiteColor;
      }
    }
  }

  .fields {
    display: flex;
    justify-content: space-between;
    margin-right: -0.2vw;
    margin-bottom: $contentDefaultIndent * 1.5;
    margin-left: -0.2vw;
    input {
      border-radius: 0;
    }
    .reason,
    .extraInput {
      padding: 0 0.2vw;
      &:first-child {
        input {
          border-top-left-radius: $uiBorderRadius;
          border-bottom-left-radius: $uiBorderRadius;
        }
      }
      &:last-child {
        input {
          border-top-right-radius: $uiBorderRadius;
          border-bottom-right-radius: $uiBorderRadius;
        }
      }
    }
    .reason {
      flex: auto;
    }
  }
  .footer {
    display: flex;
    align-items: center;
    padding-bottom: $contentDefaultIndent / 2;
  }
}

.commentsDropdownContent {
  min-width: 17rem !important;
  max-width: 22rem !important;
  margin: 0;
  overflow: hidden !important;
}
