@import "nlib/config";

.lastComment {
  display: flex;
  position: relative;
  flex: auto;
  align-items: center;
  height: 2rem;
  .content {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    flex-direction: column;
    max-width: 100%;
    height: 100%;
    padding-left: 1.75rem;
    padding-left: $contentDefaultIndent;
    border-left: 2px solid $uiMediumDarkColor;
    font-size: 0.6875rem;
    line-height: 1rem;
    cursor: pointer;
    &.hasUnread {
      border-left-color: $uiNegativeColor;
    }
    .row {
      display: flex;
      position: relative;
      svg {
        font-size: 1.2em;
        vertical-align: -0.25em;
      }
      .textFull {
        display: none;
        position: absolute;
        z-index: 19;
        top: 0;
        left: 0;
        width: max-content;
        max-width: 100%;
        transform: translateZ(0);
        border-radius: 1px;
        background: $uiWhiteColor;
        box-shadow:
          -4px 0 0 4px $uiWhiteColor,
          4px 0 0 4px $uiWhiteColor,
          0 2px 6px 6px rgba($uiBlackColor, 0.1);
        word-break: break-all;
        white-space: pre-line;
        &:hover {
          display: block;
        }
      }
      .textShort {
        display: block;
        flex: auto;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        &:hover {
          + .textFull {
            display: block;
          }
        }
      }
      .more {
        margin-left: $contentDefaultIndent;
        color: $uiMediumDarkColor;
        font-weight: 500;
        text-decoration: underline;
        white-space: nowrap;
        cursor: pointer;
        &:hover {
          text-decoration: none;
        }
      }
    }
    .createdBy {
      overflow: hidden;
      color: $uiMediumDarkColor;
      text-overflow: ellipsis;
    }
  }
  .reply {
    margin-right: $contentDefaultIndent / 2;
    color: $uiPrimaryColor;
    font-weight: 500;
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }
}
.dropdownContent {
  width: 22rem !important;
  margin: 0;
  overflow: hidden !important;
}
