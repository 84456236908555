@import "nlib/config";

.filters {
  display: flex;
  z-index: 4;
  justify-content: flex-end;
  padding: $contentDefaultIndent * 2 0;
  background-color: $uiBackgroundColor;
  &.active {
    position: relative;
    position: sticky;
    top: 0;
  }
  .search {
    flex: 1;
    max-width: 370px;
    margin-right: $contentDefaultIndent;
    input {
      + div svg {
        transform: scaleX(-1);
      }
    }
  }
  .resetButton {
    visibility: hidden;
    &.active {
      visibility: visible;
    }
  }
  .date {
    flex: 1;
    max-width: 290px;
  }
  .account,
  .type {
    flex: 1;
    max-width: 250px;
  }
  .date,
  .account,
  .type {
    margin-right: $contentDefaultIndent;
  }
}
