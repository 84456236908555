@import "nlib/config";

.progressCell {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding-right: $contentDefaultIndent / 2;
  .viewDetails {
    height: 1.5rem;
  }
  .progressStatus {
    display: flex;
    align-items: center;
    height: 2.5rem;
    margin-top: 8px;
    padding: 0 $contentDefaultIndent;
    border: 1px solid $uiBorderColor;
    border-radius: $uiBorderRadius;
    white-space: nowrap;
    &[data-step=awaitingCategorization] {
      svg {
        color: $uiSpecialColor;
      }
    }
    &[data-step=awaitingClarification] {
      svg {
        color: $uiWarningColor;
      }
    }
    &[data-step=awaitingReview] {
      svg {
        color: $uiHighlightColor;
      }
    }
    &[data-step=awaitingExport] {
      svg {
        color: $uiPositiveColor;
      }
    }
    &[data-step=awaitingReconciliation] {
      svg {
        color: $uiLightBlueColor;
      }
    }
    svg {
      font-size: 1rem;
      &[data-wait] {
        color: $uiMediumDarkColor;
        font-size: 1rem;
      }
    }
    .label {
      flex: auto;
      margin-right: $contentDefaultIndent;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
