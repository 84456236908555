@import "nlib/config";

.headerAccountantContent {
  display: flex;
  align-items: center;
  height: 100%;
  .showAllButton {
    margin-right: $contentDefaultIndent * 2;
  }
  svg {
    font-size: 1.25rem;
  }
  button {
    svg {
      font-size: 1rem;
    }
    svg + span {
      margin-left: 0.3em;
    }
    + button {
      margin-left: $contentDefaultIndent;
    }
  }
}
