@import "nlib/config";

.transactionsPage {
  .pageHeader {
    margin-bottom: $contentDefaultIndent;
  }
  .description {
    margin-bottom: $contentDefaultIndent * 2;
    color: lighten($uiMediumDarkColor, 5%);
    font-size: 0.9375rem;
    line-height: 1.25rem;
  }
  .statusFilters {
    max-width: 100%;
    overflow: hidden;
    [data-tabindex][data-status=inProgress] {
      margin-left: auto;
    }
  }
  .filters {
    &.noBottomPadding {
      padding-bottom: 0;
    }
  }
  .statusDescription {
    margin-top: $contentDefaultIndent;
    margin-bottom: $contentDefaultIndent;
    margin-left: $contentDefaultIndent * 1.5;
    font-weight: 500;
    svg {
      font-size: 1.2em;
    }
  }
}
